
import React, { useEffect, useState } from 'react';
import { Table, message, Tag, Button, Modal } from 'antd';

import { Link, useParams } from 'react-router-dom';

import { requestData } from './request';

import PtcClientDetail from './ptc-client-detail';

const columns = [{
  title: 'clientId',
  key: 'clientID',
  dataIndex: 'clientID',
}, {
  title: 'name',
  key: 'name',
  dataIndex: 'name'
}, {
  title: 'version',
  key: 'version',
  dataIndex: 'version'
}, {
  title: 'port',
  key: 'port',
  dataIndex: 'port'
}, {
  title: 'peerId',
  key: 'peerId',
  dataIndex: 'peerId'
}, {
  title: 'status',
  render: renderStatus,
}, {
  title: 'operate',
  render: renderOperate,
}];

function renderStatus(item) {
  const color = 'running' === item.status ? 'green' : 'red';
  const content = item.status;
  return (
    <Tag color={color}>{content}</Tag>
  )
}

function renderOperate(item) {

  async function onStart() {
    try {
      await requestData('startPTCClient', {
        clientId: item.clientID
      });
      message.success('ok');
    } catch (e) {
      message.error(e.message);
    }
  }

  async function onStop() {
    try {
      await requestData('stopPTCClient', {
        clientId: item.clientID
      });
      message.success('ok');
    } catch (e) {
      message.error(e.message);
    }
  }

  return (
    <div>
      <div className='users-ov-row'>
        <Link to={`/client-detail/${item.clientID}`}>
          <Button type="link">Detail</Button>
        </Link>
      </div>
      <div className='users-ov-row'>
        <Button type="link" onClick={onStart}>start</Button>
      </div>
      <div className='users-ov-row'>
        <Button type="link" onClick={onStop}>stop</Button>
      </div>
    </div>
  );
}

export default () => {

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(false);

  async function loadData() {
    setLoading(true);
    try {
      const data = await requestData('getPTCClients');
      setListData(data);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} />
    </div>
  );
}
