
import React, { useEffect, useState } from 'react';

import { requestData } from './request';
import { NodeIndexOutlined } from '@ant-design/icons';
import { Descriptions, Progress, Button, message, Space, Table, Tag, Modal } from 'antd';
import fileSize from 'filesize';
import LineChart from './line-chart';
import { Link } from 'react-router-dom';

import UserLog from './user-log';
import UserDetail from './user-detail';
import moment from 'moment';

import { isAdmin } from './util';

// const fileSize = partial({ standard: 'jedec' });

async function loadData() {
  const res = await requestData('runningUser');
  return res;
}

const columns = [{
  title: 'Overview',
  key: 'Overview',
  render: renderOverview
}, {
  title: 'progress',
  key: 'progress',
  render: renderProgress
}, {
  title: 'daily upload',
  key: 'upload',
  render: renderUpload
}, {
  title: 'trend',
  key: 'trend',
  render: renderTrend,
  width: 600
}, {
  title: 'exam',
  key: 'examInfo',
  render: renderExam,
}, {
  title: 'comment',
  key: 'comment',
  render: renderComment,
}, {
  title: 'operate',
  key: 'operate',
  render: renderOperate
}];

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
  }
  return(false);
}

function renderComment(item) {
  const { comment } = item;
  return comment.comment;
}

function renderOverview(item) {
  const { done, nickname, paid, scraperServer, type, serverIds, site, uid, vip, uploadCount, proxyAddr, comment, serverInfo, tbName, gain, proxyInfo } = item;
  const firstServerIp = _.get(serverInfo, '[0].ip');
  const firstServerPort = _.get(serverInfo, '[0].port');
  const firstServerUrl = `http://${firstServerIp}:${firstServerPort}`;

  async function onTestProxy() {
    const res = await requestData('testProxy', { id: proxyInfo.id });
    if (true === res.success) {
      message.success(res.ip);
    } else {
      message.error(res.msg);
    }
  }

  if (item.nickname === '423904') {
    console.log(item)
  }

  function renderLink() {
    if (true === comment.ptc) {
      return (
        <Link to={`/client-detail/${serverIds?.[0]}`} >
          <a>{firstServerUrl}</a>
        </Link>
      )
    } else {
      return <a href={firstServerUrl} target="_blank">{firstServerUrl}</a>
    }
  }

  const seedUser = comment.seedUser;

  return (
    <div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>nick: 
          <span className={`users-ov-item-value ${seedUser === true ? 'seed-user' : ''}`}>{nickname}</span>
        </Tag>
        <Tag className='users-ov-item'>uid: 
          <span className='users-ov-item-value'>{uid}</span>
        </Tag>
        <Tag className='users-ov-item'>site: 
          <span className='users-ov-item-value'>{site}</span>
        </Tag>
        <Tag className='users-ov-item'>tbName: 
          <span className='users-ov-item-value'>{tbName}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color='green'>paid: 
          <span className='users-ov-item-value'>{paid}</span>
        </Tag>
        <Tag className='users-ov-item' color='green'>gain: 
          <span className='users-ov-item-value'>{gain}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color={true === done ? 'green' : 'orange'}>done:
          <span className='user-ov-item-value' >{String(done)}</span>
        </Tag>
        <Tag className='users-ov-item'>vip: 
          <span className='users-ov-item-value'>{Number(vip)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color="cyan">scraper server:
          <span className='users-ov-item-value'>{scraperServer}</span>
        </Tag>
        <Tag className='users-ov-item'>server ids: 
          <span className='users-ov-item-value'>
            <a href={`http://${firstServerIp}:${firstServerPort}`}>{serverIds}</a>
          </span>
        </Tag>
        <Tag className='users-ov-item'>speed limit rate:
          <span className='users-ov-item-value'>{comment.speedLimitRate}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>server: 
          <span className='users-ov-item-value'>
            {renderLink(item)}
            {/* <a href={firstServerUrl} target="_blank">{firstServerUrl}</a> */}
          </span>
        </Tag>
      </div>
      {
        proxyInfo && (
          <div className='users-ov-row'>
            <Tag className='users-ov-item'>[{proxyInfo.id}] proxy: 
              <span className='users-ov-item-value'>
                {proxyInfo.host}:{proxyInfo.port}({proxyInfo.line})
              </span>
              <Button onClick={onTestProxy} type="text" icon={<NodeIndexOutlined />}></Button>
            </Tag>
          </div>
        )
      }
    </div>
  );
}

function renderProgress(item) {
  let { uploadCount, gmtCreate, totalUpload, totalDownload, serverData, latestSiteData, type, comment } = item;
  uploadCount = (uploadCount || 0) * 1000 * 1000 * 1000 * 1000;
  totalUpload = (totalUpload || 0);
  latestSiteData = latestSiteData || { downloadCount: 0, uploadCount: 0, shareRatio: 0 };
  const { downloadCount, uploadCount: realUpload, shareRatio } = latestSiteData;
  let eta = 0;
  if (true === Array.isArray(serverData) && 2 <= serverData.length) {
    const firstData = serverData[0];
    const latestData = serverData[serverData.length - 1];
    const timeDiff = (new Date(latestData.gmt_create)) - (new Date(firstData.gmt_create));
    const uploadDiff = latestData.upload_count - firstData.upload_count;
    const uploadRate = uploadDiff / timeDiff;
    eta = (uploadCount - totalUpload) / uploadRate;
    eta = Math.max(0, eta);
  }
  let ddlProgress = 0;
  let ddlDate = null;
  if (1 === type) {
    const now = Date.now();
    ddlDate = new Date(comment.ddl);
    const startDate = new Date(gmtCreate);
    const totalTime = ddlDate.getTime() - startDate.getTime();
    const leftTime = now - startDate.getTime();
    ddlProgress = Math.floor(leftTime / totalTime * 100);
  }
  return (
    <div>
      {
        1 === type ? (
          <div>
            <Progress percent={ddlProgress}></Progress>
            <span>{moment(gmtCreate).format('YYYY-MM-DD')} -- {moment(ddlDate).format('YYYY-MM-DD')}</span>
          </div>
        ) : null
      }
      <Progress percent={ Math.floor(totalUpload / uploadCount * 100) }></Progress>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          ↑:
          <span className='users-ov-item-value'>{fileSize(realUpload || 0, { standard: 'jedec' })}</span>
        </Tag>
        <Tag className='users-ov-item'>
          ↓: 
          <span className='users-ov-item-value'>{fileSize(downloadCount || 0)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color={"green"}>
          ETA: 
          <span className='users-ov-item-value'>{(eta / 1000 / 60 / 60).toFixed(2)}H</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          totalUp: 
          <span className='users-ov-item-value'>{fileSize(totalUpload || 0)}</span>
        </Tag>
        <Tag className='users-ov-item'>
          totalDl: 
          <span className='users-ov-item-value'>{fileSize(totalDownload || 0)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          uploadCount: 
          <span className='users-ov-item-value'>{fileSize(uploadCount || 0)}</span>
        </Tag>
      </div>
    </div>
  );
}

function renderUpload(item) {
  const { serverData } = item;
  const { downloadCount, increasedCount, uploadCount } = item.loadData;
  let realUpload = 0;
  if (2 <= serverData.length) {
    const firstData = serverData[0];
    const lastData = serverData[serverData.length - 1];
    realUpload = lastData.upload_count - firstData.upload_count;
  }
  return (
    <div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          ↓:
          <span className='users-ov-item-value'>{fileSize(downloadCount || 0)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          ↑:
          <span className='users-ov-item-value'>{fileSize(uploadCount || 0)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>
          ↑↑:
          <span className='users-ov-item-value'>{fileSize(realUpload || 0)}</span>
        </Tag>
      </div>
    </div>
  );
}

function renderTrend(item) {
  const { serverData } = item;
  for (let i = 0; i < serverData.length; i++) {
    const data = serverData[i];
    let { upload_count, upload_speed, gmt_create } = data;
    if (0 === upload_count && 0 !== i) {
      upload_count = serverData[i - 1].upload_count;
      data.upload_count = upload_count;
    }
    let countSpeed = 0;
    if (0 < i) {
      const disCount = upload_count - serverData[i - 1].upload_count;
      const disTime = ((new Date(gmt_create)).getTime() - (new Date(serverData[i - 1].gmt_create).getTime())) / 1000;
      countSpeed = Math.floor((disCount / 1024 / 1024) / disTime);
    }
    if (1 === i) {
      serverData[0].count_speed = countSpeed;
    }
    data.count_speed = countSpeed;
    data.upload_count_tb = Number((data.upload_count / 1000 / 1000 / 1000 / 1000).toFixed(3));
  }
  return (
    <div>
      <LineChart
        data={[serverData, serverData]} 
        xField={"gmt_create"}
        yField={['upload_count_tb', 'count_speed']}
        yaxis={[{
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${Number(v).toFixed(3)} TB`
          }
        }, {
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${Math.round(v)} MB`
          }
        }]}
        height={200} />
    </div>
  );
}

function renderExam(item) {
  if (true === _.isEmpty(item.examInfo)) {
    return null;
  }

  const [ latestExamInfo ] = item.examInfo;
  const { examConfig } = item;
  if (undefined === latestExamInfo || null === latestExamInfo) {
    return;
  }

  const dayTime = 24 * 60 * 60 * 1000;
  const hourTime = 60 * 60 * 1000;
  const { leftTime } = latestExamInfo;
  const dayLeftTime = leftTime % dayTime;
  const day = (leftTime - dayLeftTime) / dayTime;
  const hourLeftTime = dayLeftTime % hourTime;
  const hour = (dayLeftTime - hourLeftTime) / hourTime;
  return (
    <div>
      <div>
        <Tag>LeftTime: {day}D {hour}H</Tag>
        {
          examConfig.upload && (
            <Tag color={latestExamInfo.upload > examConfig.upload ? 'green' : 'red'}>
              Upload: {fileSize(latestExamInfo.upload)}/{fileSize(examConfig.upload)}
            </Tag>
          )
        }
        {
          examConfig.download && (
            <Tag color={latestExamInfo.download > examConfig.download ? 'green' : 'red'}>
              Download: {fileSize(latestExamInfo.download)}/{fileSize(examConfig.download)}
            </Tag>
          )
        }
        {
          examConfig.magicPoint && (
            <Tag color={latestExamInfo.magicPoint > examConfig.magicPoint ? 'green' : 'red'}>
              MagicPoint: {latestExamInfo.magicPoint}/{examConfig.magicPoint}
            </Tag>
          )
        }
        {
          examConfig.seedTime && (
            <Tag color={latestExamInfo.seedTime > examConfig.seedTime ? 'green' : 'red'}>
              SeedTime: {latestExamInfo.seedTime}/{examConfig.seedTime}
            </Tag>
          )
        }
        {
          examConfig.seedRatio && (
            <Tag color={latestExamInfo.seedRatio > examConfig.seedRatio ? 'green' : 'red'}>
              SeedRatio: {latestExamInfo.seedRatio}/{examConfig.seedRatio}
            </Tag>
          )
        }
        {
          examConfig.shareRatio && (
            <Tag color={latestExamInfo.shareRatio > examConfig.shareRatio ? 'green' : 'red'}>
              ShareRatio: {latestExamInfo.shareRatio}/{examConfig.shareRatio}
            </Tag>
          )
        }
        
      </div>
    </div>
  )
}

async function onChangeServer(item) {
  const serverId = window.prompt();
  if (null === serverId) {
    return;
  }
  const serverIdNum = Number(serverId);
  if (false === _.isNumber(serverIdNum)) {
    message.error(`invalid server id: [${serverIdNum}]`);
    return;
  }

  const { uid, site } = item;
  await requestData('updateScraper', {
    serverId, uid, site
  });
  message.success('success!');
}

async function onDelete(item) {
  if (null === item.gain) {
    Modal.error({
      title: '删除失败',
      content: 'gain 为 null，先更新 gain，刷新页面后再删除，小号或者送人就写 -1，其他号收到多少钱填多少'
    });
    return;
  }
  const { nickname, uid, site } = item;
  const res = window.confirm(`do you want to delete user: [${nickname}]`);
  if (false === res) {
    return;
  }
  await requestData('deleteUser', { uid, site });
  message.success('success!');
}

async function onAllocServer(item) {
  const { id, comment } = item;
  let type = undefined;
  if (true === comment.ptc) {
    type = 'ptc';
  }
  await requestData('allocDownloadServer', {
    userId: id,
    type
  });
  message.success('success!');
}

async function onChangeSpeed(item) {
  const { uid, site, comment } = item;
  const rate = window.prompt('speed rate', comment.speedLimitRate);
  if (null === rate) {
    return;
  }

  const rateNum = Number(rate);
  if (false === _.isNumber(rateNum)) {
    message.warn('not a valid number!');
    return;
  }
  await requestData('changeSpeedLimitRate', { uid, site, rate })
  message.success('success!');
}

function onViewUserLog(item) {
  const { uid, nickname } = item;
  setUserNickFunc(nickname);
  setShowLogUserFunc(uid);
  setShowModalFunc(true);
}

async function onUpdateUser(item) {
  const { uid, nickname, comment } = item;
  setShowUserDetailFunc(true);
  setUserNickFunc(nickname);
  const userDetail = [];

  for (const name in comment) {
    item[name] = comment[name];
  }

  for (let name in item) {
    let value = item[name];
    switch (name) {
      case 'serverIds':
        name = 'bindServer';
        value = value.join(',');
        break;
      case 'vipNormalItemCount':
        name = 'vipNormalCount';
        break;
      case 'type':
        name = 'userType';
        break;
      default:
        break;
    }
    if (true === _.isArray(value) || true === _.isObject(value)) {
      continue;
    }
    userDetail.push({
      name,
      value
    });
  }
  console.log('-------', userDetail);
  setUserDetailFunc(userDetail);
}

function renderOperate(item) {
  const admin = isAdmin();
  let adminOps = <></>;
  if (true === admin) {
    adminOps = (
      <>
        <div className='users-ov-row'>
          <Button onClick={ () => onAllocServer(item)} type="link">Alloc Server</Button>
        </div>
        <div className='users-ov-row'>
          <Button onClick={() => onChangeServer(item)} type="link">Scraper</Button>
        </div>
      </>
    )
  }
  return (
    <div>
      <div className='users-ov-row'>
        <Button onClick={ () => onViewUserLog(item)} type="link">View Log</Button>
      </div>
      {adminOps}
      <div className='users-ov-row'>
        <Button onClick={() => onUpdateUser(item)} type="link">Update</Button>
      </div>
      <div className='users-ov-row'>
          <Button onClick={() => onDelete(item)} type="link">Delete</Button>
        </div>
    </div>
  );
}

let setUserNickFunc = null;
let setShowLogUserFunc = null;
let setShowModalFunc = null;
let setUserSiteFunc = null;
let setShowUserDetailFunc = null;
let setUserDetailFunc = null;

export default () => {
  
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [showLogUser, setShowLogUser] = useState(undefined);
  const [userDetail, setUserDetail]= useState([]);
  const [userNick, setUserNick] = useState('');
  const [userSite, setUserSite] = useState('');

  const [loading, setLoading] = useState(false);
  setUserNickFunc = setUserNick;
  setShowLogUserFunc = setShowLogUser;
  setShowModalFunc = setShowModal;
  setUserSiteFunc = setUserSite;
  setShowUserDetailFunc = setShowUserDetail;
  setUserDetailFunc = setUserDetail;

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setLoading(false);
      const admin = isAdmin();
      let users = [];
      if (true === admin) {
        users = data;
      } else {
        for(const item of data) {
          if (true === item.comment.adminUser) {
            continue;
          }
          users.push(item);
        }
      }
      setListData(users);
    }).catch(() => {
      setLoading(false);
    });
  }, []);
  return (
    <div className="users">
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} />
      <Modal width={'50%'} title={userNick} visible={showModal} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} >
        <UserLog key={showLogUser} uid={showLogUser} site={userSite}></UserLog>
      </Modal>
      <Modal width={'50%'} title={userNick} visible={showUserDetail} onOk={() => onUpdateUserInfo(false)} onCancel={() => setShowUserDetail(false)} >
        <UserDetail
          fields={userDetail}
          requestType="update"
          update={true}
        />
      </Modal>
    </div>
  );
}
