
import React, { useEffect, useState } from 'react';
import { Table, message, Tag, Button } from 'antd';

import {
  CheckOutlined
} from '@ant-design/icons';

import { useAsyncError, useParams } from 'react-router-dom';

import { requestData } from './request';
import fileSize from 'filesize';
import moment from 'moment';

function filesizeWithZero(number) {
  if (true === isNaN(Number(number))) {
    return fileSize(0);
  } else {
    return fileSize(Number(number))
  }
}

const columns = [{
  title: 'name',
  key: 'name',
  dataIndex: 'name',
  width: 100
}, {
  title: 'complete',
  key: 'complete',
  dataIndex: 'numComplete',
  width: 100,
  sorter: (a, b) => a.numComplete - b.numComplete,
  showSorterTooltip: true,
  sortDirections: ['ascend', 'descend']
}, {
  title: 'incomplete',
  key: 'incomplete',
  dataIndex: 'numIncomplete',
  width: 100,
  sorter: (a, b) => a.numIncomplete - b.numIncomplete,
  showSorterTooltip: true
}, {
  title: 'size',
  key: 'size',
  width: 100,
  sorter: (a, b) => a.size - b.size,
  showSorterTooltip: true,
  render: (item) => {
    return filesizeWithZero(item.size);
  },
}, {
  title: 'hash',
  key: 'hash',
  width: 100,
  dataIndex: 'infoHash'
}, {
  title: 'down',
  key: 'down',
  width: 100,
  sorter: (a, b) => a.downloaded - b.downloaded,
  render: (item) => {
    return filesizeWithZero(item.downloaded);
  }
}, {
  title: 'up',
  key: 'up',
  sorter: (a, b) => a.uploaded - b.uploaded, 
  width: 100,
  render: (item) => {
    return filesizeWithZero(item.uploaded);
  },
  width: 100
}, {
  title: 'down speed',
  key: 'downSpeed',
  width: 100,
  sorter: (a, b) => a.downloadSpeed - b.downloadSpeed, 
  render: (item) => {
    return `${filesizeWithZero(item.downloadSpeed)}/s`
  }
}, {
  title: 'up speed',
  key: 'upSpeed',
  sorter: (a, b) => a.uploadSpeed - b.uploadSpeed, 
  render: (item) => {
    return `${filesizeWithZero(item.uploadSpeed)}/s`
  },
  width: 100
}, {
  title: 'progress',
  key: 'progress',
  width: 100,
  sorter: (a, b) => a.progress - b.progress, 
  render: (item) => {
    return `${((item?.progress || 0) * 100).toFixed(2)}%`
  },
}, {
  title: 'status',
  key: 'status',
  width: 100,
  render: renderStatus,
}, {
  title: 'warning',
  key: 'warningMessage',
  width: 100,
  render: renderWarning,
}, {
  title: 'lastReport',
  key: 'lastReport',
  width: 100,
  sorter: (a, b) => (new Date(a?.lastReport)).getTime() - (new Date(b?.lastReport)).getTime(), 
  render: (item) => {
    return moment(item.lastReport).format('MM-DD HH:mm:SS');
  }
}, {
  title: 'add on',
  key: 'addOn',
  width: 100,
  sorter: (a, b) => (new Date(a?.gmtCreate)).getTime() - (new Date(b?.gmtCreate)).getTime(), 
  render: (item) => {
    return moment(item.gmtCreate).format('MM-DD HH:mm:SS');
  }
}, {
  title: 'operate',
  key: 'operate',
  render: renderOperate,
  width: 100
}];

function renderOperate(item) {
  async function onDelete() {
    try {
      await requestData('removeTorrent', {
        clientId: item.clientId,
        hash: item.infoHash
      });
      message.success('ok');
    } catch (e) {
      message.error(e.message);
    }
  }
  return (
    <div>
      <div className='users-ov-row'>
        <Button type="link" onClick={onDelete}>delete</Button>
      </div>
    </div>
  );
}

function renderStatus(item) {
  const { working, failureReason } = item;
  if (1 === working) {
    return <CheckOutlined style={{color: 'green'}}/>
  } else if (failureReason) {
    return <span style={{color: 'red'}}>{failureReason}</span>
  }
}

function renderWarning(item) {
  return <span style={{color: 'yellow'}}>{item.warningMessage}</span>
}

export default () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(false);
  const [downSpeed, setDownSpeed] = useState(0);
  const [upSpeed, setUpSpeed] = useState(0);
  const [downCount, setDownCount] = useState(0);
  const [upCount, setUpCount] = useState(0);

  async function loadData() {
    setLoading(true);
    try {
      const data = await requestData('getPTCTorrents', {
        clientId: Number(params.clientId)
      });
      let downSpeedVal = 0;
      let upSpeedVal = 0;
      let downCountVal = 0;
      let upCountVal = 0;
      for (const item of data) {
        const { uploadSpeed, downloadSpeed, downloaded, uploaded } = item;
        const numUploadSpeed = Number(uploadSpeed);
        const numDownloadSpeed = Number(downloadSpeed);
        const numDownloaded = Number(downloaded);
        const numUploaded = Number(uploaded);
        if(false === _.isNaN(numUploadSpeed)) {
          upSpeedVal += numUploadSpeed;
        }
        if(false === _.isNaN(numDownloadSpeed)) {
          downSpeedVal += numDownloadSpeed;
        }
        if(false === _.isNaN(numDownloaded)) {
          downCountVal += numDownloaded;
        }
        if(false === _.isNaN(numUploaded)) {
          upCountVal += numUploaded;
        }
      }
      setDownSpeed(downSpeedVal);
      setUpSpeed(upSpeedVal);
      setDownCount(downCountVal);
      setUpCount(upCountVal);
      setListData(data);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div>
        <span>
          download speed: 
          <Tag color="yellow">{filesizeWithZero(downSpeed)}/s</Tag>
        </span>
        <span>
          up speed: 
          <Tag color="green">{filesizeWithZero(upSpeed)}/s</Tag>
        </span>
      </div>
      <div>
        <span>
          download count: 
          <Tag color="yellow">{filesizeWithZero(downCount)}</Tag>
        </span>
        <span>
          up count: 
          <Tag color="green">{filesizeWithZero(upCount)}</Tag>
        </span>
      </div>
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} scroll={{y: '100%'}} />
    </div>
  );
}
