import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.dark.css';
import { createBrowserRouter, MemoryRouter, BrowserRouter, Outlet, RouterProvider, Route, Routes } from 'react-router-dom';

import App from './App';
import Portal from './portal';
import Users from './users';
import Servers from './servers';
import Sites from './sites';
import UserDetail from './user-detail';
import RegCode from './reg-code';
import Income from './income';
import Proxy from './proxy';
import DiskInfo from './disk-info';
import Clients from './ptc-client-list';
import ClientDetail from './ptc-client-detail';

const router = createBrowserRouter([
  {
    'path': '/',
    Component: App,
    children: [{
      path: 'income',
      Component: Income
    }]
  }
]);

const fields = [
  {
    name: 'increaseRate',
    value: 6131.13
  },
  {
    name: 'userType',
    value: 0
  },
  {
    name: 'bindServer',
    value: '-1'
  }, 
  {
    name: 'proxy',
    value: true
  },
  {
    name: 'proxyAddr',
    value: 'http://tracker.perterpon.com:4230'
  }, 
  {
    name: 'cycleTime',
    value: 30
  },
  {
    name: 'vipNormalCount',
    value: 10000
  },
  {
    name: 'singleDownload',
    value: true
  },
  {
    name: 'speedLimitRate',
    value: 0.75
  }, 
  {
    name: 'maxShareRatio',
    value: 19.19
  },
  {
    name: 'increaseVersion',
    value: 5
  },
  {
    name: 'needExam',
    value: false
  },
  {
    name: 'ptc',
    value: true
  },
  {
    name: 'zeroShotRate',
    value: 98,
  },
  {
    name: 'moreIncreaseRate',
    value: 90,
  },
  {
    name: 'downloadSpeed',
    value: 20_000_000,
  },
  {
    name: 'zeroShotPeers',
    value: 10
  },
  {
    name: 'adminUser',
    value: false,
  },
  {
    name: 'seedUser',
    value: false
  }
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MemoryRouter>
      <Routes>
        <Route path="/" Component={App}>
          <Route path="portal" Component={Portal} />
          <Route path="users" Component={Users} />
          <Route path="servers" Component={Servers} />
          <Route path="sites" Component={Sites} />
          <Route path="userdetail" element={<UserDetail requestType="create" fields={fields}></UserDetail>} />
          <Route path="regcode" Component={RegCode} />
          <Route path="proxy" Component={Proxy} />
          <Route path="disk" Component={DiskInfo} />
          <Route path="clients" Component={Clients} />
          <Route path="client-detail/:clientId" Component={ClientDetail} />
          <Route path="" Component={Income} />
        </Route>
      </Routes>
    </MemoryRouter>
  </React.StrictMode>
);
