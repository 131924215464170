
export function isAdmin() {
  let mode = getQueryVariable('mode');
  if (false !== mode) {
    window.localStorage.setItem('pt-mode', mode);
  } else {
    mode = window.localStorage.getItem('pt-mode');
  }
  return 'admin' === mode;
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
  }
  return(false);
}