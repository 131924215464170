
import React, { useEffect, useState } from 'react';

import { Table } from 'antd';
import { requestData } from './request';
import fileSize from 'filesize';

const columns = [{
  title: 'name',
  dataIndex: 'name',
}, {
  title: 'domain',
  dataIndex: 'domain',
}, {
  title: 'tracker_domain',
  dataIndex: 'trackerDomain'
}, {
  title: 'box limit',
  dataIndex: 'boxLimit',
  render: (item) => {
    return `${item}`
  }
}, {
  title: 'hr?',
  dataIndex: 'hr',
  render: (item) => {
    return `${item}`
  }
}, {
  title: 'speed limit',
  dataIndex: 'speedLimit',
  render: (item) => {
    return `${fileSize(item)}M/s`
  }
}, {
  title: 'sensitive',
  dataIndex: 'sensetive',
  render: (item) => {
    return `${item}`
  }
}, {
  title: 'comment',
  dataIndex: 'comment'
}];

export default () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    requestData('allSites').then((res) => {
      setLoading(false);
      setData(res);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Table columns={columns} loading={loading} dataSource={data}  pagination={false}/>
    </div>
  );
}

