
import React, { useEffect, useState } from 'react';

import { Descriptions, Statistic, PageHeader, Table } from 'antd';

import { requestData } from './request';
import filesize from 'filesize';

import LineChart from './line-chart';

async function loadTrendData() {
  const res = await requestData('overviewInfo');
  return res;
}

const columns = [{
  title: 'time',
  dataIndex: 'gmtCreate',
}, {
  title: 'server id',
  dataIndex: 'serverId',
}, {
  title: 'up count',
  dataIndex: 'uploadTorrents'
}, {
  title: 'dl count',
  dataIndex: 'downloadTorrents',
}, {
  title: 'up speed',
  dataIndex: 'uploadSpeed',
  render: (item) => {
    return `${filesize(item)}/s`
  }
}, {
  title: 'dl speed',
  dataIndex: 'downloadSpeed',
  render: (item) => {
    return `${filesize(item)}/s`
  }
}, {
  title: 'up size',
  dataIndex: 'uploadCount',
  render: (item) => {
    return `${filesize(item)}`
  }
}, {
  title: 'dl size',
  dataIndex: 'downloadCount',
  render: (item) => {
    return `${filesize(item)}`
  }
}];

const Portal = () => {
  const [dailyUpload, setDailyUpload] = useState(0);
  const [actualUpload, setActualUpload] = useState(0);
  const [increasedUpload, setIncreasedUpload] = useState(0);
  const [fakeDlNumber, setFakeDlNumber] = useState(0);
  const [fakeDlSize, setFakeDlSize] = useState(0);
  const [realDlNumber, setRealDlNumber] = useState(0);
  const [realDlSize, setRealDlSize] = useState(0);
  const [downloadingNumber, setDownloadingNumber] = useState(0);
  const [downloadingSize, setDownloadingSize] = useState(0);
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [trendData, setTrendData] = useState([]);

  useEffect(() => {
    loadTrendData().then((res) => {
      setDailyUpload(res.overViewData.uploadCount);
      setActualUpload(res.overViewData.downloadCount);
      setIncreasedUpload(res.overViewData.increasedCount);

      setFakeDlNumber(res.downloadData.fakeDlNumber);
      setFakeDlSize(res.downloadData.fakeDlSize);
      setRealDlNumber(res.downloadData.realDlNumber);
      setRealDlSize(res.downloadData.realDlSize);
      setDownloadingNumber(res.downloadData.downloadingNumber);
      setDownloadingSize(res.downloadData.downloadingSize);
      // setTrendData(res.serverData);
    });
    
  }, []);


  useEffect(() => {
    setLoading(true);
    requestData('allRunningServer').then((res) => {
      setLoading(false);
      setSiteData(res);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div>
        <Descriptions>
          <Descriptions.Item>
            <Statistic title="Daily Upload:" value={filesize(dailyUpload)}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Actual Upload:" value={filesize(actualUpload)}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Increased Upload:" value={filesize(increasedUpload)}></Statistic>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div>
        <Descriptions>
          <Descriptions.Item>
            <Statistic title="Real Download Number:" value={realDlNumber}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Fake Download Number:" value={fakeDlNumber}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Downloading Number:" value={downloadingNumber}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Real Download Size:" value={filesize(realDlSize)}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Fake Download Size:" value={filesize(fakeDlSize)}></Statistic>
          </Descriptions.Item>
          <Descriptions.Item>
            <Statistic title="Download Size:" value={filesize(downloadingSize)}></Statistic>
          </Descriptions.Item>
        </Descriptions>
      </div>
      {/* <div className='line-chart-container'>
        <LineChart data={trendData} xField={'gmt_create'} yField={'upload_speed'} seriesField={'name'}></LineChart>
      </div> */}
      <br />
      <div>
        <Table columns={columns} pagination={false} loading={loading} dataSource={siteData}></Table>
      </div>
    </>
  );
}

export default Portal;