
import React, { useEffect, useState } from 'react';

import { requestData } from './request';
import { Descriptions, Progress, Button, message, Space, Table, Tag, Modal } from 'antd';
import LinkChart from './line-chart';
// import { partial } from 'filesize';
import fileSize from 'filesize';
import LineChart from './line-chart';

import UserLog from './user-log';
import UserDetail from './user-detail';
import moment from 'moment';

// const fileSize = partial({ standard: 'jedec' });

async function loadData(method) {
  const res = await requestData(method);
  return res;
}

const columns = [{
  title: 'Overview',
  key: 'Overview',
  render: renderOverview
}, {
  title: 'comment',
  key: 'comment',
  render: renderComment,
}, {
  title: 'operate',
  key: 'operate',
  render: renderOperate
}];

function renderComment(item) {
  return _.get(item, 'comment.comment');
}

function renderOverview(item) {
  const { done, nickname, paid, scraperServer, type, serverIds, site, uid, vip, uploadCount, proxyAddr, serverInfo, tbName, gain, proxyInfo } = item;
  console.log('proxyInfo: ', proxyInfo);
  const comment = _.get(item, 'comment', {});
  return (
    <div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item'>nick: 
          <span className='users-ov-item-value'>{nickname}</span>
        </Tag>
        <Tag className='users-ov-item'>uid: 
          <span className='users-ov-item-value'>{uid}</span>
        </Tag>
        <Tag className='users-ov-item'>site: 
          <span className='users-ov-item-value'>{site}</span>
        </Tag>
        <Tag className='users-ov-item'>tbName: 
          <span className='users-ov-item-value'>{tbName}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color='green'>paid: 
          <span className='users-ov-item-value'>{paid}</span>
        </Tag>
        <Tag className='users-ov-item' color={0 === gain ? 'red' : 'green'}>gain: 
          <span className='users-ov-item-value'>{gain}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color={true === done ? 'green' : 'orange'}>done:
          <span className='user-ov-item-value' >{String(done)}</span>
        </Tag>
        <Tag className='users-ov-item'>vip: 
          <span className='users-ov-item-value'>{Number(vip)}</span>
        </Tag>
      </div>
      <div className='users-ov-row'>
        <Tag className='users-ov-item' color="cyan">scraper server:
          <span className='users-ov-item-value'>{scraperServer}</span>
        </Tag>
        <Tag className='users-ov-item'>speed limit rate:
          <span className='users-ov-item-value'>{comment?.speedLimitRate}</span>
        </Tag>
      </div>
      {
        proxyInfo && (
          <div className='users-ov-row'>
          socks5:
            <Tag className='users-ov-item'>
              {proxyInfo.user}:{proxyInfo.password}@{proxyInfo.host}:{proxyInfo.port}
            </Tag>
          </div>
        )
      }
    </div>
  );
}

function onViewUserLog(item) {
  const { uid, nickname } = item;
  setUserNickFunc(nickname);
  setShowLogUserFunc(uid);
  setShowModalFunc(true);
}

async function onUpdateUser(item) {
  const { uid, nickname, comment } = item;
  setShowUserDetailFunc(true);
  setUserNickFunc(nickname);
  const userDetail = [];

  for (const name in comment) {
    item[name] = comment[name];
  }

  for (let name in item) {
    let value = item[name];
    switch (name) {
      case 'serverIds':
        name = 'bindServer';
        value = value.join(',');
        break;
      case 'vipNormalItemCount':
        name = 'vipNormalCount';
        break;
      case 'type':
        name = 'userType';
        break;
      default:
        break;
    }
    if (true === _.isArray(value) || true === _.isObject(value)) {
      continue;
    }
    userDetail.push({
      name,
      value
    });
  }
  setUserDetailFunc(userDetail);
}

function renderOperate(item) {
  return (
    <div>
      <div className='users-ov-row'>
        <Button onClick={ () => onViewUserLog(item)} type="link">View Log</Button>
      </div>
      <div className='users-ov-row'>
        <Button onClick={() => onUpdateUser(item)} type="link">Update</Button>
      </div>
    </div>
  );
}

let setUserNickFunc = null;
let setShowLogUserFunc = null;
let setShowModalFunc = null;
let setUserSiteFunc = null;
let setShowUserDetailFunc = null;
let setUserDetailFunc = null;

export default (props) => {

  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [showLogUser, setShowLogUser] = useState(undefined);
  const [userDetail, setUserDetail]= useState([]);
  const [userNick, setUserNick] = useState('');
  const [userSite, setUserSite] = useState('');

  const [loading, setLoading] = useState(false);
  setUserNickFunc = setUserNick;
  setShowLogUserFunc = setShowLogUser;
  setShowModalFunc = setShowModal;
  setUserSiteFunc = setUserSite;
  setShowUserDetailFunc = setShowUserDetail;
  setUserDetailFunc = setUserDetail;

  useEffect(() => {
    setLoading(true);
    loadData(props.type).then((data) => {
      setLoading(false);
      setListData(data);
    }).catch(() => {
      setLoading(false);
    });
  }, []);
  return (
    <div className="users">
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} />
      <Modal width={'50%'} title={userNick} visible={showModal} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} >
        <UserLog key={showLogUser} uid={showLogUser} site={userSite}></UserLog>
      </Modal>
      <Modal width={'50%'} title={userNick} visible={showUserDetail} onOk={() => onUpdateUserInfo(false)} onCancel={() => setShowUserDetail(false)} >
        <UserDetail
          fields={userDetail}
          requestType="update"
        />
      </Modal>
    </div>
  );
}
