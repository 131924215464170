
import React, { useCallback, useEffect, useState } from 'react';
import { Table, Modal, Button, Form, Input, DatePicker, message } from 'antd';
import { requestData } from './request';
import { NodeIndexOutlined } from '@ant-design/icons';
import moment from 'moment';

async function onTestProxy(id) {
  const res = await requestData('testProxy', { id });
  if (true === res.success) {
    message.success(res.ip);
  } else {
    message.error(res.msg);
  }
}

function Operate({item}) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onFinish = useCallback((editItem) => {
    setVisible(false);
    requestData('updateProxy', editItem);
    message.success('success');
  }, []);
  const onEdit = useCallback(() => {
    setVisible(true);
  }, []);
  const onDelete = useCallback(() => {
    Modal.confirm({
      title: "warning",
      content: `delete proxy: [${item.host}:${item.port}, ${item.line}, ${item.comment}]?`,
      onOk: async () => {
        requestData('deleteProxy', item);
        message.success('success');
      }
    });
  });
  if (item) {
    item.startAt = moment(item.start_at);
    item.endAt = moment(item.end_at);
  }
  return (
    <>
      <Button type="link" onClick={onEdit}>Edit</Button>
      <Button type="link" onClick={onDelete}>Delete</Button>
      <ProxyDetailForm title="update proxy" onFinish={onFinish} visible={visible} setVisible={setVisible} confirmLoading={confirmLoading} initialValues={item} />
    </>
  );
}

const columns = [
  {
    title: 'host',
    dataIndex: 'host',
    key: 'host',
    render: (host, item) => {
      return (
        <div>
          <span>{host}</span>
          <Button onClick={onTestProxy.bind(undefined, item.id)} type="text" icon={<NodeIndexOutlined />}></Button>
        </div>
      )
    }
  },
  {
    title: 'port',
    dataIndex: 'port',
    key: 'port',
  },
  {
    title: 'user',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'password',
    dataIndex: 'password',
    key: 'password',
  },
  {
    title: 'start_at',
    dataIndex: 'start_at',
    key: 'start_at',
    render(value) {
      return moment(value).format('YYYY-MM-DD');
    }
  },
  {
    title: 'end_at',
    dataIndex: 'end_at',
    key: 'end_at',
    render(value) {
      const endAt = moment(value);
      const now = moment();
      const displayValue = endAt.format('YYYY-MM-DD');
      if (now.isAfter(endAt)) {
        return <span style={{color: 'red'}}>{displayValue}</span>;
      } else if (now.add(3, 'days').isAfter(endAt)) {
        return <span style={{color: 'yellow'}}>{displayValue}</span>;
      } else {
        return <span>{displayValue}</span>
      }
    }
  },
  {
    title: 'line',
    dataIndex: 'line',
    key: 'line',
  },
  {
    title: 'bandwidth (M)',
    dataIndex: 'bandwidth',
    key: 'bandwidth',
  },
  {
    title: 'comment',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'edit',
    render(value) {
      return <Operate item={value}/>
    }
  }
];

function ProxyDetailForm({visible, setVisible, onFinish, confirmLoading, initialValues, title}) {
  return (
    <Modal forceRender={false} footer={false} title={title} onCancel={setVisible.bind(undefined, false)} visible={visible}>
        <Form onFinish={onFinish} initialValues={initialValues}>
          <Form.Item rules={[{required: true}]} label="host" name="host">
            <Input></Input>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label="port" name="port">
            <Input></Input>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label="user" name="user">
            <Input></Input>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label="password" name="password">
            <Input></Input>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label="start_at" name="startAt">
            <DatePicker></DatePicker>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label="end_at" name="endAt">
            <DatePicker></DatePicker>
          </Form.Item>
          <Form.Item label="comment" name="comment">
            <Input></Input>
          </Form.Item>
          <Form.Item label="line" name="line">
            <Input></Input>
          </Form.Item>
          <Form.Item label="bandwidth" name="bandwidth">
            <Input></Input>
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button loading={confirmLoading} type="primary" htmlType="submit">submit</Button>
          </Form.Item>
        </Form>
      </Modal>
  );
}

export default () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addProxyVisible, setProxyVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await requestData('getProxys');
      setLoading(false);
      setData(res);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  async function onAddProxy(formValues) {
    setConfirmLoading(true);
    try {
      await requestData('addProxy', formValues);
      setProxyVisible(false);
      message.success('success');
    } catch (e) {
    } finally {
      setConfirmLoading(false);
    }
  }

  return (
    <div>
      <h1>Proxy</h1>
      <Button type='primary' onClick={setProxyVisible.bind(undefined, true)}>add proxy</Button>
      <br />
      <Table pagination={false} columns={columns} dataSource={data} Spin={loading} />
      <ProxyDetailForm title='add proxy' onFinish={onAddProxy} visible={addProxyVisible} confirmLoading={confirmLoading} setVisible={setProxyVisible} />
    </div>
  );
}

