
import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/charts';
import { Table, Spin } from 'antd';
import { requestData } from './request';
import LineChart from './line-chart';

export default () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    requestData('dailyIncome').then((res) => {
      setLoading(false);
      setData(res);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h1>Daily Income</h1>
      <Spin spinning={loading}>
        <Line 
          data={data}
          xField="date"
          yField="income"
        />
      </Spin>
    </div>
  );
}

