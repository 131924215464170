
import React, { useEffect, useState } from 'react';

import { requestData } from './request';
import { NodeIndexOutlined } from '@ant-design/icons';
import { Descriptions, Progress, Button, message, Space, Table, Tag, Modal } from 'antd';
import { Line } from '@ant-design/charts';
// import { partial } from 'filesize';
import fileSize from 'filesize';
import LineChart from './line-chart';

import UserLog from './user-log';
import UserDetail from './user-detail';
import moment from 'moment';

// const fileSize = partial({ standard: 'jedec' });

async function loadData() {
  const res = await requestData('getDiskInfo');
  return res;
}

const columns = [{
  title: 'slot',
  key: 'slot',
  render: (item) => {
    return String(item.slot);
  }
}, {
  title: 'sn',
  key: 'sn',
  render: (item) => {
    return String(item.sn);
  }
}, {
  title: 'size',
  key: 'size',
  render: (item) => {
    return String(item.size);
  }
}, {
  title: 'pdType',
  key: 'pdType',
  render: (item) => {
    return String(item.pdType);
  }
}, {
  title: 'status',
  key: 'status',
  render: (item) => {
    return String(item.status);
  }
}, {
  title: 'temperature',
  key: 'temperatures',
  render: renderTemperature,
}];

function renderTemperature(item) {
  return <Line
    data={item.temperatures}
    xField={"gmtCreate"}
    yField={'temperature'}
    height={200}
  />
}

function renderTrend(item) {
  const { serverData } = item;
  for (let i = 0; i < serverData.length; i++) {
    const data = serverData[i];
    let { upload_count, upload_speed, gmt_create } = data;
    if (0 === upload_count && 0 !== i) {
      upload_count = serverData[i - 1].upload_count;
      data.upload_count = upload_count;
    }
    let countSpeed = 0;
    if (0 < i) {
      const disCount = upload_count - serverData[i - 1].upload_count;
      const disTime = ((new Date(gmt_create)).getTime() - (new Date(serverData[i - 1].gmt_create).getTime())) / 1000;
      countSpeed = Math.floor((disCount / 1024 / 1024) / disTime);
    }
    if (1 === i) {
      serverData[0].count_speed = countSpeed;
    }
    data.count_speed = countSpeed;
    data.upload_count_tb = Number((data.upload_count / 1000 / 1000 / 1000 / 1000).toFixed(3));
  }
  return (
    <div>
      <LineChart
        data={[serverData, serverData]} 
        xField={"gmt_create"}
        yField={['upload_count_tb', 'count_speed']}
        yaxis={[{
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${Number(v).toFixed(3)} TB`
          }
        }, {
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${Math.round(v)} MB`
          }
        }]}
        height={200} />
    </div>
  );
}

let setUserNickFunc = null;
let setShowLogUserFunc = null;
let setShowModalFunc = null;
let setUserSiteFunc = null;
let setShowUserDetailFunc = null;
let setUserDetailFunc = null;

export default () => {
  
  const [listData, setListData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setLoading(false);
      const snMap = {};
      const listData = [];
      for (const item of data) {
        const { gmtCreate, sn, temperature, status, size, linkSpeed, pdType, slot } = item;
        let tarItem = snMap[sn];
        if (undefined === tarItem) {
          tarItem = { sn, status, size, linkSpeed, pdType, temperatures: [], slot};
          snMap[sn] = tarItem;
        }
        tarItem.temperatures.push({
          gmtCreate, temperature
        });
      }

      for (const key in snMap) {
        const value = snMap[key];
        listData.push(value);
      }
      setListData(listData);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  }, []);
  return (
    <div className="disk-infos">
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} />
    </div>
  );
}
