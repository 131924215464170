
import React from "react"
import { Button, Form, Input, InputNumber, Select, Switch, message } from 'antd';

import { requestData } from './request';
import TextArea from "antd/lib/input/TextArea";

export default (props) => {

  let fields = [];

  function onSubmit() {
    const params = {};
    for (const field of fields) {
      const { name, value } = field;
      params[name[0]] = value;
    }

    requestData('addRegCode', params).then(() => {
      message.success('success');
    });
  }

  return (
    <Form
      name="reg-code"
      fields={props.fields}
      onFieldsChange={(_, allFields) => {
        fields = allFields;
      }}
      onFinish={onSubmit}
      onFinishFailed={(arg1, arg2) => console.log(arg1, arg2)}
    >
      <Form.Item
        name="regCode"
        label="regCode"
        rules={[{required: true}]}
      >
        <TextArea autoSize />
      </Form.Item>
      <Form.Item
        name="paid"
        label="paid"
        rules={[{required: true}]}
      >
        <InputNumber autoSize />
      </Form.Item>
      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        <Button type="primary" htmlType="submit">submit</Button>
      </Form.Item>
    </Form>
  );
}
