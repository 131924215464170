
import React, { useEffect, useState } from "react"
import { Button, Form, Input, InputNumber, Select, Switch, message } from 'antd';

import { requestData } from './request';
import TextArea from "antd/lib/input/TextArea";
import { isAdmin } from "./util";

const LinePicker = (props) => {
  if (undefined === props.value || undefined === props.site) {
    return null;
  }
  const [proxys, setProxys] = useState([]);
  const [users, setUsers] = useState([]);

  async function loadData() {
    const proxys = await requestData('getProxys');
    const users = await requestData('allUser');
    const runningUser = [];
    for (const user of users) {
      if (true === user.done) {
        continue;
      }
      runningUser.push(user);
    }
    setProxys(proxys);
    setUsers(runningUser);
  }

  function foundBestProxy() {
    if (undefined === props.site) {
      props.onChange(-1);
      return;
    }
    if (true === props.update || proxys.length === 0) {
      return;
    }
    const targetSite = props.site;
    const proxyMap = {};

    let targetProxyId = -1;
    // 0. found never used proxy
    for (const proxy of proxys) {
      const usedProxy = _.find(users, { proxyId: proxy.id });
      if (undefined === usedProxy) {
        targetProxyId = proxy.id;
        break;
      }
    }
    if (-1 !== targetProxyId) {
      props.onChange(targetProxyId);
      return;
    }

    for (const user of users) {
      // 1. find all target site users
      if (user.site !== targetSite) {
        continue;
      }
      // 2. count used proxys
      const proxyCount = proxyMap[user.proxyId];
      if (undefined === proxyCount) {
        proxyMap[user.proxyId] = 0;
      }
      proxyMap[user.proxyId] = proxyMap[user.proxyId] + 1;
    }

    // 3. loop all proxy, find the most less used proxy
    let minCount = 999999;
    for (const proxy of proxys) {
      const count = proxyMap[proxy.id];
      if (undefined === count) {
        targetProxyId = proxy.id;
        break;
      }
      if (count < minCount) {
        minCount = count;
        targetProxyId = proxy.id;
      }
    }
    props.onChange(targetProxyId);
  }

  // useEffect(foundBestProxy, [props.site, users, proxys]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Select value={props.value} onChange={(proxyId) => props.onChange(proxyId)}>
      <Select.Option key={-1} value={null}>
        不需要代理
      </Select.Option>
      {
        proxys.map((proxy) => {
          const usedSites = {};
          for (const user of users) {
            if (user.done === true) {
              continue;
            }

            if (user.proxyId === proxy.id) {
              let siteCount = usedSites[user.site];
              if (undefined === siteCount) {
                siteCount = 0
                usedSites[user.site] = siteCount;
              }
              usedSites[user.site] = siteCount + 1;
            }
          }
          let siteInfo = '';
          for (const site in usedSites) {
            siteInfo = `${siteInfo}${site}: ${usedSites[site]}  `;
          }
          return (
            <Select.Option key={proxy.id} value={proxy.id}>
              line id: [{proxy.id}] {proxy.line} {siteInfo}
            </Select.Option>
          )
        })
      }
    </Select>
  )
}

export default (props) => {
  let fields = [];
  function onSubmit(fields) {
    const params = {
      requestType: props.requestType,
      ...fields,
    };
    // for (const field of fields) {
    //   const { name, value } = field;
    //   params[name[0]] = value;
    // }
    setLoading(true);
    params.downloadSpeed = params.downloadSpeed * 1000 * 1000;
    requestData('addUser', params).then(() => {
      setLoading(false);
      message.success('success');
    }).catch(() => {
      setLoading(false);
    })
  }

  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  let defaultSite = undefined;
  const formValue = _.cloneDeep(props.fields);
  for (const field of (formValue || [])) {
    const { name, value } = field || {};
    if ('site' === name) {
      defaultSite = value;
      continue;
    }

    if ('downloadSpeed' === name) {
      field.value = value / 1000 / 1000;
    }
  }
  const [currentSite, setCurrentSite] = useState(defaultSite);

  useEffect(() => {
    requestData('allSites').then((data) => {
      setSites(data);
    })
  }, []);

  const options = [];
  for (const site of sites) {
    options.push(
      <Select.Option value={site.name}>{site.name}({site.comment})</Select.Option>
    );
  }

  return (
    <Form
      name="user-detail"
      fields={formValue}
      onFieldsChange={(_, allFields) => {
        fields = allFields;
        if ('site' === _?.[0]?.name?.[0]) {
          setCurrentSite(_[0].value);
        }
      }}
      onFinish={onSubmit}
      onFinishFailed={(arg1, arg2) => console.log(arg1, arg2)}
    >
      <Form.Item
        name="site"
        label="site"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          disabled={true === props.update}
        >
          {options}
        </Select>
      </Form.Item>
      <Form.Item
        name="userType"
        label="user type"
        rules={[{required: true}]}
        tooltip="用户类型，纯刷量，或者包月，包月也要填写上传量"
      >
        <Select>
          <Select.Option value={0}>刷量</Select.Option>
          <Select.Option value={1}>包月</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ddl"
        label="ddl"
        tooltip="包月截止时间，基本要跟vip截止时间一致，或者要早于vip结束时间，否则vip结束就容易被封号"
      >
        <Input placeholder="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item
        name="vip"
        label="vip"
        tooltip="VIP要把这个开关打开，否则VIP不会生效"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="vipDDL"
        label="vip ddl"
        tooltip="vip截止时间，不要写错"
      >
        <Input placeholder="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item
        name="proxyId"
        label="line"
        tooltip="选择线路，每条线路会标明正在被使用的站点和数量，会自动选择人少的线路，一般不用改"
        initialValue={null}
      >
        <LinePicker site={currentSite} update={props.update} />
      </Form.Item>
      <Form.Item
        name="tbName"
        label="tb name"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="cookie"
        label="cookie"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="uploadCount"
        label="upload count"
        rules={[{required: true}]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="paid"
        label="paid"
        rules={[{required: true}]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="uid"
        label="uid"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nickname"
        label="nickname"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="passkey"
        label="passkey"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="cycleTime"
        label="cycle time"
        rules={[{required: true}]}
        tooltip="千万不要改！！！"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="bindServer"
        label="bind server"
        rules={[{required: true}]}
        tooltip="千万不要改！！！"
      >
        <InputNumber disabled={true === props.update} />
      </Form.Item>
      <Form.Item
        name="rss"
        label="rss"
        tooltip="不要改"
        rules={[{required: true}]}
      >
        <Input  />
      </Form.Item>
      
      <Form.Item
        name="vipNormalCount"
        label="vipNormalCount"
        tooltip="不要改"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="increaseRate"
        label="increase rate"
        rules={[{required: true}]}
        tooltip="上传倍数，非vip不要改，馒头vip可以写30000+，随便写个非整数就行"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="proxy"
        label="proxy"
        tooltip="千万不要改！！！！"
        valuePropName="checked"
      >
        <Switch defaultChecked disabled={false} />
      </Form.Item>
      <Form.Item
        name="proxyAddr"
        label="proxy addr"
        tooltip="千万不要改！！！！"
      >
        <Input disabled={false} />
      </Form.Item>
      <Form.Item
        name="speedLimitRate"
        label="speed Limit Rate"
        tooltip="非VIP勿动，馒头vip写2，非VIP只要大于1就会被超速封号！！！！"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="maxShareRatio"
        label="max share ratio"
        tooltip="vip可以适当提高，馒头VIP可以写500，非VIP千万勿改！！！"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="increaseVersion"
        label="increase version"
        tooltip="千万不要改！！！！"
      >
        <Select disabled={false}>
          <Select.Option value={1}>1</Select.Option>
          <Select.Option value={1}>2</Select.Option>
          <Select.Option value={1}>3</Select.Option>
          <Select.Option value={1}>4</Select.Option>
          <Select.Option value={1}>5</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="singleDownload"
        label="soft link"
        tooltip="是否要启用软连接开关，一般默认打开"
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name="needExam"
        label="need exam"
        tooltip="是否需要新手考核"
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name="gain"
        label="gain"
        tooltip="总共收到多少钱"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="ptc"
        label="ptc"
        tooltip="is using pt client"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="adminUser"
        label="adminUser"
        tooltip="is admin user"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="seedUser"
        label="seedUser"
        tooltip="is seedUser user"
        valuePropName="checked"
      >
        <Switch defaultChecked={false} />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="zeroShotRate"
        label="zeroShotRate"
        tooltip="zero shot rate"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="moreIncreaseRate"
        label="moreIncreaseRate"
        tooltip="more increase rate"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="downloadSpeed"
        label="downloadSpeed"
        tooltip="download speed"
      >
        <InputNumber addonAfter="MB/s" />
      </Form.Item>
      <Form.Item
        hidden={false === isAdmin()}
        name="zeroShotPeers"
        label="zeroShotPeers"
        tooltip="zero shot peers"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="comment"
        label="comment"
        tooltip="备注信息"
      >
        <TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        <Button loading={loading} type="primary" htmlType="submit">submit</Button>
      </Form.Item>
    </Form>
  );
}
