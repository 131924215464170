

import React from 'react';

import { Tabs } from 'antd';

import RunningUserList from './running-user-list';
import UserList from './user-list';

export default () => {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="running user" key="1">
          <RunningUserList></RunningUserList>
        </Tabs.TabPane>
        <Tabs.TabPane tab="no gain user" key="2">
          <UserList type="noGainUser"></UserList>
        </Tabs.TabPane>
        <Tabs.TabPane tab="all user" key="3">
          <UserList type="allUser"></UserList>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
